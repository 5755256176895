<template>
  <v-dialog v-model="show" max-width="750px" @keydown.esc="cancel">
    <running-dialog
      message="Loading. Please wait..."
      :showDialog.sync="loadingStartDates"
    ></running-dialog>
    <error-dialog
      message="Error. Cannot find funded dates."
      :showDialog.sync="showStartDateErrorDialog"
    ></error-dialog>
    <v-card>
      <v-card-title>
        <span class="headline">
          Set Account Properties
        </span>
        <span class="right-corner">
          <v-icon @click="cancel" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  label="Fee Frequency"
                  v-model="editedItem.feeFrequency"
                  clearable
                  :items="feeFrequencies"
                  item-text="displayName"
                  item-value="value"
                  :color="theme.dropDown"
                  :item-color="theme.dropDownItem"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <account-search
                  :selectNew="selectNew"
                  :theme="theme"
                  searchLabel="Paid By"
                ></account-search>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Billable Status"
                  v-model="editedItem.billableStatus"
                  clearable
                  :items="billableStatuses"
                  item-text="displayName"
                  item-value="value"
                  :color="theme.dropDown"
                  :item-color="theme.dropDownItem"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subheading">Start Date</div>
                <v-date-picker
                  v-model="editedItem.startDate"
                  label="Start Date"
                  :min="minStartDate"
                  :disabled="disabledStartDate"
                  scrollable
                  :color="theme.datePicker"
                ></v-date-picker>
              </v-col>
              <v-col>
                <div class="subheading">End Date</div>
                <v-date-picker
                  label="End Date"
                  v-model="editedItem.endDate"
                  :color="theme.datePicker"
                ></v-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-5">
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 mr-2"
          :style="theme.textColor"
          :color="theme.buttonWhite"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          class="mb-2 mr-2"
          :color="theme.button"
          :dark="valid && !notChanged"
          @click="confirm"
          :disabled="!valid || notChanged"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { getPreviousRunIntFromString } from "@/utils/period-util.js";
import AccountSearch from "@/components/common/AccountSearch.vue";
import amplitude from "amplitude-js";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";

export default {
  components: {
    AccountSearch,
    ErrorDialog,
    RunningDialog,
  },
  props: [
    "showDialog",
    "accounts",
    "theme",
    "allSelected",
    "billingPeriod",
    "runType",
    "filters",
    "quickSearch"
  ],
  emits: ["update:showDialog", "confirm"],
  data: () => ({
    disabledStartDate: false,
    minStartDate: null,
    loadingStartDates: false,
    showStartDateErrorDialog: false,
    valid: false,
    editedItem: {
      startDate: null,
      endDate: null,
      feeFrequency: "",
      paidBy: "",
      billableStatus: ""
    },
    feeFrequencies: [
      { displayName: "Monthly in Arrears", value: "Monthly in Arrears" },
      { displayName: "Monthly in Advance", value: "Monthly in Advance" },
      { displayName: "Quarterly in Advance", value: "Quarterly in Advance" },
      { displayName: "Quarterly in Arrears", value: "Quarterly in Arrears" },
      { displayName: "Annual in Arrears", value: "Annual in Arrears" }
    ],
    billableStatuses: [
      { displayName: "Yes", value: "Yes" },
      { displayName: "No", value: "No" }
    ],
  }),
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    notChanged() {
      return (
        this.editedItem.startDate == null &&
        this.editedItem.endDate == null &&
        (this.editedItem.feeFrequency == "" ||
          this.editedItem.feeFrequency == undefined) &&
        (this.editedItem.paidBy == "" ||
          this.editedItem.paidBy == null ||
          this.editedItem.paidBy == undefined) &&
        (this.editedItem.billableStatus == "" ||
          this.editedItem.billableStatus == undefined)
      );
    },
  },
  watch: {
    show(val) {
      setTimeout(() => {
        if (val) {
          if (this.allSelected) {
            this.setPropertiesFilters(this.filters);
            this.setQuickSearchParam(this.quickSearch);
          } else {
            this.resetPropertiesFilters();
            this.resetQuickSearchParam();
          }
          this.loadStartDateDetails();
        }
      }, 100);
    }
  },
  methods: {
    ...mapMutations("addUpdateAccounts", [
      "setPropertiesFilters",
      "resetPropertiesFilters",
      "setQuickSearchParam",
      "resetQuickSearchParam"
    ]),
    ...mapActions("entityAttributes", ["getMinStartDatesForAccounts"]),
    async loadStartDateDetails() {
      this.disabledStartDate = true;
      this.minStartDate = null;

      let finished = false;
      setTimeout(() => {
        if (!finished) {
          this.loadingStartDates = true;
        }
      }, 3000);

      let payload = {};
      if (this.allSelected) {
        payload = {
          all: true,
          billingPeriod: getPreviousRunIntFromString(this.billingPeriod),
          runType: this.runType,
          accountIds: []
        };
      } else {
        payload = {
          all: false,
          accountIds: this.accounts.map(a => a.id)
        };
      }
      let allowedDates = await this.getMinStartDatesForAccounts(payload);
      finished = true;
      this.loadingStartDates = false;

      if (allowedDates) {
        this.setMinStartDate(allowedDates);
        this.disabledStartDate = false;
      } else {
        this.showStartDateErrorDialog = true;
      }
    },
    setMinStartDate(allowedDates) {
      allowedDates.filter(a => a.minDate !== null);
      let minResults = allowedDates.map(d => d.minDate);
      if (minResults.length == 0) {
        return;
      }
      minResults.sort().reverse();
      let minResult = minResults[0];
      this.minStartDate = minResult;
    },
    async confirm() {
      let payload = {
        feeFrequency: this.editedItem.feeFrequency,
        paidBy: this.editedItem.paidBy,
        startDate: this.editedItem.startDate,
        endDate: this.editedItem.endDate,
        billableStatus: this.editedItem.billableStatus,
      };
      amplitude.getInstance().logEvent("Inception [Change Properties]", {
        "Fee Frequency": this.editedItem.feeFrequency,
        "Paid By": this.editedItem.paidBy,
        "Start Date": this.editedItem.startDate,
        "End Date": this.editedItem.endDate,
        "Billable Status": this.editedItem.billableStatus,
      });
      if (this.allSelected) {
        payload = { ...payload, all: true };
      } else {
        payload = { ...payload, accounts: this.accounts };
      }
      this.$emit("confirm", payload);
      this.cancel();
    },
    cancel() {
      this.reset();
      this.$emit("update:showDialog", false);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.editedItem.startDate = null;
      this.editedItem.endDate = null;
      this.editedItem.paidBy = "";
      this.disabledStartDate = false;
      this.minStartDate = null;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    selectNew(val) {
      if (val === undefined || val === null || val.name === null) {
        this.editedItem.paidBy = null;
      } else {
        this.editedItem.paidBy = val.name;
      }
    }
  }
};
</script>
