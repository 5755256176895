<template>
  <v-container fluid>
    <success-dialog
      :message="runStartedMessage"
      :showDialog.sync="runCalcStartSuccess"
    ></success-dialog>
    <review-fees-and-schedules
      :items="inceptionCalculationResults"
      :selectedPeriod="billingPeriod"
      :accounts="filteredInceptionAccounts"
      :showBillingRecordsErrorDialog="showBillingRecordsErrorDialog"
      :showCalculationErrorDialog="showCalculationErrorDialog"
      :calculationWarningDialog.sync="calculationWarningDialog"
      :calculationWarningMessage.sync="calculationWarningMessage"
      :totalCalculationResults="totalInceptionCalculationResults"
      :refresh="refresh"
      :refreshOrCut="refreshOrCut"
      :refreshAndModify="refreshAndModify"
      :reRun="reRun"
      :selectAllAccounts="selectAllAccounts"
      @back="back"
      @set-accounts-for-re-run="setInceptionAccountsForReRun"
    ></review-fees-and-schedules>
  </v-container>
</template>

<script>
import { getCurrentPeriod } from "@/utils/period-util.js";
import { mapState, mapActions, mapMutations } from "vuex";
import ReviewFeesAndSchedules from "@/components/billing-records/ReviewFeesAndSchedules.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";

export default {
  emits: ["back"],
  components: {
    ReviewFeesAndSchedules,
    SuccessDialog,
  },
  data: () => ({
    calculationWarningDialog: false,
    calculationWarningMessage: "",
    runStartedMessage: "Running of calculation started. Check calculation status in Fee Review.",
    runCalcStartSuccess: false,
    showCalculationErrorDialog: false,
    showBillingRecordsErrorDialog: false
  }),
  computed: {
    ...mapState("inceptionInAdvanceAccounts", ["filteredInceptionAccounts"]),
    ...mapState("inceptionRecords", [
      "inceptionCalculationResults",
      "totalInceptionCalculationResults",
      "inceptionAccountsForReRun",
      "inceptionAccountsForCalculation",
    ]),
    billingPeriod() {
      return getCurrentPeriod();
    },
  },
  methods: {
    ...mapMutations("inceptionRecords", [
      "setInceptionAccountsForReRun",
      "setInceptionCalculationResults"
    ]),
    ...mapActions("inceptionRecords", [
      "getInceptionAccountRecords",
      "reRunInception",
      "createAndViewInceptionBillingRecords",
      "getAllInceptionBillingRecordsPaged"
    ]),
    back() {
      this.$emit("back");
    },
    async reRun(payload) {
      this.calculationWarningMessage = "";
      this.calculationWarningDialog = false;
      let accounts;
      if (payload.all) {
        accounts = this.inceptionAccountsForCalculation;
      } else {
        accounts = this.inceptionAccountsForReRun;
      }
      let result = await this.reRunInception({
        page: 1,
        pageSize: payload.itemsPerPage,
        accounts: accounts
      });

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showCalculationErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (!result.responseData) {
        this.showCalculationErrorDialog = true;
      }
    },
    async refreshAndModify(payload) {
      let result = await this.getInceptionAccountRecords(payload);
      return result;
    },
    async refreshOrCut(options) {
      let array = this.inceptionCalculationResults;
      if (options.itemsPerPage > array.length) {
        await this.refresh(options);
      } else {
        this.setInceptionCalculationResults(
          this.inceptionCalculationResults.slice(0, options.itemsPerPage)
        );
      }
    },
    async refresh(options) {
      let result = await this.createAndViewInceptionBillingRecords({
        page: options.page,
        pageSize: options.itemsPerPage,
      });

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showBillingRecordsErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (!result.responseData) {
        this.showBillingRecordsErrorDialog = true;
      }
    },
    async selectAllAccounts(options) {
      options.accounts = this.inceptionAccountsForCalculation;
      let allAccounts = await this.getAllInceptionBillingRecordsPaged(options);
      return allAccounts;
    }
  }
};
</script>
