<template>
  <v-dialog
    v-model="showInception"
    persistent
    width="auto"
    @keydown.esc="closeInceptionWizard('FeeAssignment')"
  >
    <v-card>
      <running-dialog
        message="Filtering accounts. Please wait..."
        :showDialog.sync="filteringInceptionAccounts"
      ></running-dialog>
      <v-card-title>
        <span class="headline"> Inception Billing Wizard </span>
        <span class="right-corner">
          <v-icon
            @click="closeInceptionWizard('FeeAssignment')"
            class="exit-icon"
          >
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-row no-gutters>
        <inception-accounts
          v-if="screen == 'accounts'"
          @next="refreshAndShowFilteredAccounts"
        ></inception-accounts>
        <inception-filtered-accounts
          v-else-if="screen == 'filtered'"
          @back="showAccounts"
          @show-records="showBillingRecords"
          @show-exports="showExports"
        ></inception-filtered-accounts>
        <inception-records
          v-else-if="screen == 'records'"
          @back="refreshAndShowFilteredAccounts"
        ></inception-records>
        <export-results
          v-else-if="screen == 'exports'"
          @back="showAccounts"
          @next="closeInceptionWizard('DownloadPortal')"
        ></export-results>
      </v-row>
      <app-version></app-version>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import InceptionAccounts from "@/components/inception-billing/InceptionAccounts.vue";
import InceptionFilteredAccounts from "@/components/inception-billing/InceptionFilteredAccounts.vue";
import InceptionRecords from "@/components/inception-billing/InceptionRecords.vue";
import ExportResults from "@/views/ExportResults.vue";
import AppVersion from "@/views/AppVersion.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";

export default {
  components: {
    InceptionAccounts,
    InceptionFilteredAccounts,
    InceptionRecords,
    ExportResults,
    AppVersion,
    RunningDialog,
  },
  data: () => ({
    screen: "accounts",
    filteringInceptionAccounts: false,
  }),
  computed: {
    ...mapState("inceptionAccounts", [
      "selectedInceptionAccounts",
      "inceptionAllSelected",
    ]),
    ...mapState("inceptions", ["showInception"]),
    ...mapState("userConfig", ["colorTheme"]),
  },
  methods: {
    ...mapMutations("filters", [
      "resetInceptionFilters",
      "resetQuickSearchInception"
    ]),
    ...mapMutations("inceptions", ["setShowInception"]),
    ...mapMutations("inceptionInAdvanceAccounts", [
      "resetFilteredInceptionAccounts"
    ]),
    ...mapActions("inceptions", ["closeInceptionWizard"]),
    ...mapActions("inceptionInAdvanceAccounts", ["inceptionInAdvanceSearch"]),
    showAccounts() {
      this.screen = "accounts";
    },
    showFilteredAccounts() {
      this.screen = "filtered";
    },
    showBillingRecords() {
      this.screen = "records";
    },
    showExports() {
      this.screen = "exports";
    },
    async refreshAndShowFilteredAccounts() {
      this.filteringInceptionAccounts = true;
      this.resetFilteredInceptionAccounts();
      let payload = [];
      if (!this.inceptionAllSelected) {
        payload = this.selectedInceptionAccounts.map(a => a.id);
      }
      await this.inceptionInAdvanceSearch(payload);
      this.showFilteredAccounts();
      this.filteringInceptionAccounts = false;
    },
  },
  mounted() {
    this.resetInceptionFilters();
    this.resetQuickSearchInception();
    this.setShowInception(true);
  },
};
</script>
