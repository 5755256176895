<template>
  <v-container fluid>
    <v-card>
      <running-dialog
        message="Getting billing records. Please wait..."
        :showDialog.sync="gettingBillingRecords"
      ></running-dialog>
      <success-dialog
        :message="runStartedMessage"
        :showDialog.sync="runCalcStartSuccess"
      ></success-dialog>
      <warning-dialog
        :message="calculationWarningMessage"
        :showDialog.sync="calculationWarningDialog"
      ></warning-dialog>
      <error-dialog
        message="Billing Records Error! Try again."
        :showDialog.sync="showBillingRecordsErrorDialog"
      ></error-dialog>
      <empty-exports-dialog
        :showDialog.sync="showEmptyExportsDialog"
      ></empty-exports-dialog>
      <error-dialog
        message="Export error!"
        :showDialog.sync="showExportErrorDialog"
      ></error-dialog>
      <success-dialog
        :message="exportStartedMessage"
        :showDialog.sync="exportStarted"
      ></success-dialog>
      <warning-dialog
        :message="exportWarningMessage"
        :showDialog.sync="exportWarningDialog"
      ></warning-dialog>
      <info-dialog
        :message="exportInfoMessage"
        :showDialog.sync="showExportInfoMessage"
      ></info-dialog>
      <v-card-title> Link Accounts to Account Groups </v-card-title>
      <inception-group-picker
        :showDialog.sync="showInceptionGroupPicker"
        :accounts.sync="selected"
        @showLinkingSuccessDialog="successfullyLinked = true"
        @showLinkingWarningDialog="notAllLinked = true"
        @showLinkingErrorDialog="linkingFailed = true"
        :key="groupPickerComponentKey"
      >
      </inception-group-picker>
      <success-dialog
        message="Linked successfully"
        :showDialog.sync="successfullyLinked"
      ></success-dialog>
      <warning-dialog
        message="Some accounts not linked"
        :showDialog.sync="notAllLinked"
      ></warning-dialog>
      <error-dialog
        message="Error!"
        :showDialog.sync="linkingFailed"
      ></error-dialog>
      <column-configurator
        :showDialog.sync="showConfiguratorDialog"
        :defaultHeaders="inceptionHeaders"
        :savedHeaders.sync="savedHeaders"
        :nameOfSetting="inceptionTableSettingName"
      ></column-configurator>
      <v-container fluid>
        <v-row justify="end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="showConfigurator">
                <v-icon :color="isColumnConfiguratorUsed ? colorTheme.icon : ''">
                  mdi-cog
                </v-icon>
              </v-btn>
            </template>
            <span> Choose columns </span>
          </v-tooltip>
        </v-row>
      </v-container>
      <v-card-title>
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          label="Search in results"
          single-line
          hide-details
          :color="colorTheme.textField"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        fixed-header
        class="scrollable-table__medium"
        v-model="selected"
        :headers="headersSelected"
        :items="filteredInceptionAccounts"
        :options.sync="options"
        :search="search"
        item-key="id"
        show-select
        :single-select="false"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
        <template v-slot:[`item.modelId`]="{ item }">
          <cell-clipboard :text="item.modelId" />
        </template>
        <template v-slot:[`item.aum`]="{ item }">
          <cell-monetary-value :value="item.aum" />
        </template>
        <template v-slot:[`item.aumDate`]="{ item }">
          <date-cell :value="item.aumDate" />
        </template>
        <template v-slot:[`item.billingStartDate`]="{ item }">
          <date-cell :value="item.billingStartDate" />
        </template>
        <template v-slot:[`item.billingEndDate`]="{ item }">
          <date-cell :value="item.billingEndDate" />
        </template>
        <v-data-table :headers="inceptionHeaders">
          <template v-slot:no-data>
            <v-alert :value="true" :color="colorTheme.alert">
              No in advance accounts...
            </v-alert>
          </template>
        </v-data-table>
      </v-data-table>
      <span>
        <v-card-text class="py-0 mx-1 my-1" style="min-height: 2em;">
          Accounts selected:<span :class="activeEffect && 'number-effect'">
            {{ totalSelectedInAdvanceAccounts }}
          </span>
        </v-card-text>
      </span>
      <v-card-text v-if="filterDifference > 0">
        <span>
          <v-icon :color="colorTheme.warning"> mdi-alert </v-icon>
          Warning!
        </span>
        {{ filterDifference }} out of
        {{ totalSelectedInceptionAccounts }} selected Accounts are not billable
        or do not belong to "in Advance" category.
      </v-card-text>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <regular-button
            buttonText="Back"
            :theme="colorTheme"
            :disabled="false"
            :action="back"
          ></regular-button>
          <regular-button
            buttonText="Select All"
            :theme="colorTheme"
            :disabled="false"
            :action="selectAll"
          ></regular-button>
          <regular-button
            buttonText="Select None"
            :theme="colorTheme"
            :disabled="buttonDisabled"
            :action="selectNone"
          ></regular-button>
        </span>
        <span class="mr-2">
          <button-with-tooltip
            buttonText="Export Custodian File"
            :tooltipText="exportCustodianTooltipText"
            :theme="colorTheme"
            :disabled="buttonDisabled"
            :action="generateCustodian"
          ></button-with-tooltip>
          <button-with-tooltip
            buttonText="Export Payout File"
            :tooltipText="exportPayoutTooltipText"
            :theme="colorTheme"
            :disabled="buttonDisabled"
            :action="generatePayout"
          ></button-with-tooltip>
          <button-with-tooltip
            buttonText="Link to Account Group"
            :tooltipText="linkToGroupTooltipText"
            :theme="colorTheme"
            :disabled="buttonDisabled"
            :action="showGroupPicker"
          ></button-with-tooltip>
          <button-with-tooltip
            buttonText="Calculate & Review Fees"
            :tooltipText="calculateFeesTooltipText"
            :theme="colorTheme"
            :disabled="buttonDisabled"
            :action="createAndView"
          ></button-with-tooltip>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import amplitude from 'amplitude-js'
import { mapActions, mapMutations, mapState } from "vuex";
import { getCurrentPeriod, getPreviousRunIntFromString } from "@/utils/period-util.js";
import { getMonthYearFromYearMonthPeriod } from "@/utils/format-util.js";

import InceptionGroupPicker from "./dialogs/InceptionGroupPicker";
import CellClipboard from "@/components/common/CellClipboard.vue";
import EmptyExportsDialog from "@/components/fee-results/dialogs/EmptyExportsDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import DateCell from "@/components/common/DateCell.vue";
import ColumnConfigurator from "@/components/dialogs/ColumnConfigurator.vue";

export default {
  emits: ["back", "show-records", "show-exports"],
  components: {
    InceptionGroupPicker,
    CellClipboard,
    EmptyExportsDialog,
    ErrorDialog,
    RunningDialog,
    SuccessDialog,
    WarningDialog,
    CellMonetaryValue,
    InfoDialog,
    RegularButton,
    ButtonWithTooltip,
    DateCell,
    ColumnConfigurator,
  },
  data: () => ({
    search: null,
    activeEffect: false,
    runStartedMessage: "Running of calculation started. Check calculation status in Fee Review.",
    runCalcStartSuccess: false,
    gettingBillingRecords: false,
    showBillingRecordsErrorDialog: false,
    calculationWarningDialog: false,
    calculationWarningMessage: "",
    showInceptionGroupPicker: false,
    selected: [],
    totalSelectedInAdvanceAccounts: 0,
    showEmptyExportsDialog: false,
    showExportErrorDialog: false,
    exportStarted: false,
    exportStartedMessage: "",
    exportWarningDialog: false,
    exportWarningMessage: "",
    showExportInfoMessage: false,
    exportInfoMessage: "",
    successfullyLinked: false,
    notAllLinked: false,
    linkingFailed: false,
    showConfiguratorDialog: false,
    savedHeaders: [],
  }),
  computed: {
    ...mapState("inceptionAccounts", ["totalSelectedInceptionAccounts"]),
    ...mapState("inceptionInAdvanceAccounts", ["filteredInceptionAccounts"]),
    ...mapState("billingRecordsCalculations", ["defaultPagination"]),
    ...mapState("inceptions", ["pagination", "inceptionHeaders"]),
    ...mapState("inceptionGroupPicker", ["groupPickerComponentKey"]),
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    ...mapState("columnConfigurator", ["inceptionTableSettingName"]),
    linkToGroupTooltipText() {
      return "Make account a member of a group.";
    },
    exportCustodianTooltipText() {
      return `Generate custodian file.<br />
              Retrieve this file in download portal.<br />
              Set billing record status to "Pending".<br />
              (${this.formattedPeriod} billing data)`;
    },
    exportPayoutTooltipText() {
      return `Generate payout file.<br />
              Retrieve this file in download portal.<br />
              Set billing record status to "Paid Out".<br />
              (${this.formattedPeriod} billing data)`;
    },
    calculateFeesTooltipText() {
      return `Calculate fee amounts for accounts & sleeves.<br />
              Set billing record status to "Calculated".<br />
              (${this.formattedPeriod} billing data)`;
    },
    totalFilteredInceptionAccounts() {
      return this.filteredInceptionAccounts.length;
    },
    filterDifference() {
      return (
        this.totalSelectedInceptionAccounts -
        this.totalFilteredInceptionAccounts
      );
    },
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    buttonDisabled() {
      return this.selected.length == 0;
    },
    billingPeriod() {
      return getCurrentPeriod();
    },
    formattedPeriod() {
      return getMonthYearFromYearMonthPeriod(this.billingPeriod);
    },
    isColumnConfiguratorUsed() {
      return this.inceptionHeaders.length !== this.headersSelected.length;
    },
    headersSelected() {
      let filteredHeaders = this.savedHeaders.filter(
        (header) => header.selected == true
      );
      return filteredHeaders;
    },
  },
  watch: {
    totalSelectedInAdvanceAccounts() {
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    selected(val) {
      this.totalSelectedInAdvanceAccounts = val.length;
      let accounts = val.map(account => {
        return {
          id: account.id,
          billingRunType: "Inception"
        };
      });
      this.setInceptionAccountsForCalculation(accounts);
    },
  },
  methods: {
    ...mapMutations("inceptions", ["setPagination"]),
    ...mapMutations("inceptionRecords", ["setInceptionAccountsForCalculation"]),
    ...mapActions("inceptionRecords", ["createAndViewInceptionBillingRecords"]),
    ...mapActions("exports", ["filterExport"]),
    ...mapMutations("billingRecordsCalculations", [
      "setBillingRecordsPagination"
    ]),
    showConfigurator() {
      this.showConfiguratorDialog = true;
    },
    async generateCustodian() {
      amplitude.getInstance().logEvent("Inception [Send to Custodian]", {
        "Number of accounts": this.selected.length
      });
      await this.generateExport("Custodian");
    },
    async generatePayout() {
      amplitude.getInstance().logEvent("Inception [Payout]", {
        "Number of accounts on Table": this.selected.length
      });
      await this.generateExport("Payout");
    },
    async generateExport(exportType) {
      let payload = {
        runType: "Inception",
        period: getPreviousRunIntFromString(this.billingPeriod),
        exportType: exportType,
        selectedAccounts: this.selected
      };
      let result = await this.filterExport(payload);

      switch (result.status) {
        case "SUCCESS":
          this.$emit("show-exports");
          break;
        case "INFO":
          this.exportInfoMessage = result.message;
          this.showExportInfoMessage = true;
          break;
        case "EMPTY":
          this.showEmptyExportsDialog = true;
          break;
        case "WARNING":
          this.exportWarningMessage = result.message;
          this.exportWarningDialog = true;
          break;
        case "TIMEOUT":
          this.exportStartedMessage = `${exportType} Export is taking too long. Check status in Fee Review.`;
          this.exportStarted = true;
          break;
        default:
          this.showExportErrorDialog = true;
      }
    },
    selectAll() {
      this.selected = this.filteredInceptionAccounts;
    },
    selectNone() {
      this.selected = [];
    },
    back() {
      this.$emit("back");
    },
    async createAndView() {
      amplitude.getInstance().logEvent("Inception [Create and View Billing Records]");
      this.gettingBillingRecords = true;
      let result = await this.createAndViewInceptionBillingRecords({
        page: this.defaultPagination.page,
        pageSize: this.defaultPagination.itemsPerPage
      });
      this.gettingBillingRecords = false;

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showBillingRecordsErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (result.responseData) {
        this.setBillingRecordsPagination(this.defaultPagination);
        this.$emit("show-records");
      } else {
        this.showBillingRecordsErrorDialog = true;
      }
    },
    showGroupPicker() {
      amplitude.getInstance().logEvent("Inception [Link Accounts to Account Groups]", {
        "Number of accounts on Table": this.selected
      });
      this.showInceptionGroupPicker = !this.showInceptionGroupPicker;
    },
  },
  mounted() {
    this.selectNone();
  },
};
</script>
