<template>
  <v-container fluid>
    <error-dialog
      message="Loading inceptions failed! Try again"
      :showDialog.sync="searchError"
    ></error-dialog>
    <running-dialog
      message="Updating properties. Please wait..."
      :showDialog.sync="updatingProperties"
    ></running-dialog>
    <error-dialog
      message="Update error! Try again."
      :showDialog.sync="updatingError"
    ></error-dialog>
    <success-dialog
      message="Updated successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <warning-dialog
      :message="warningMessage"
      :showDialog.sync="showWarningDialog"
    ></warning-dialog>
    <column-configurator
      :showDialog.sync="showConfiguratorDialog"
      :defaultHeaders="inceptionHeaders"
      :savedHeaders.sync="savedHeaders"
      :nameOfSetting="inceptionTableSettingName"
    ></column-configurator>
    <v-card>
      <v-card-title> Set Account Properties </v-card-title>
      <change-properties
        :showDialog.sync="showChangeInception"
        :accounts="selected"
        :theme="theme"
        :allSelected="allSelected"
        :billingPeriod="billingPeriod"
        runType="Inception"
        :filters="inceptionFilters"
        :quickSearch="quickSearchInception"
        @confirm="confirmUpdate"
      ></change-properties>
      <div style="display: flex; justify-content: space-between;">
        <span style="width: 95%;" class="bottom-align">
          <inception-filter
            ref="filters"
            :headers="headersSelected"
            :theme="theme"
            :results="inceptionAccounts"
            :totalResults="totalInceptionAccounts"
            :totalDistinctResults="totalDistinctInceptionAccounts"
            :refresh="refresh"
          ></inception-filter>
        </span>
        <span style="width: 5%;" class="bottom-align">
          <v-container fluid>
            <v-row justify="end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="showConfigurator">
                    <v-icon :color="isColumnConfiguratorUsed ? theme.icon : ''">
                      mdi-cog
                    </v-icon>
                  </v-btn>
                </template>
                <span> Choose columns </span>
              </v-tooltip>
            </v-row>
          </v-container>
        </span>
      </div>
      <advanced-account-table
        screen="inception"
        :headersSelected="headersSelected"
        :pagination.sync="options"
        :accounts="inceptionAccounts"
        :totalAccounts="totalInceptionAccounts"
        :totalDistinctAccounts="totalDistinctInceptionAccounts"
        :selectedAccounts.sync="selected"
        :filters="inceptionFilters"
        :allSelected="allSelected"
        :loading="loading"
        :theme="theme"
        :refresh="refresh"
        :refreshOrCut="refreshOrCut"
      ></advanced-account-table>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span class="number-effect_space">
          <v-row>
            <v-col cols="12" md="8">
              <v-card-text class="py-0 mx-1 my-1">
                Accounts selected:
                <span :class="activeEffect && 'number-effect'">
                  {{ totalSelected }}
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                class="py-0 mx-1 my-1"
                label="ALL"
                v-model="allSelected"
                :color="theme.switch"
              ></v-switch>
            </v-col>
          </v-row>
        </span>
      </v-row>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <regular-button
            buttonText="Select None"
            :theme="theme"
            :disabled="buttonDisabled"
            :action="selectNone"
          ></regular-button>
        </span>
        <span class="mr-2">
          <button-with-tooltip
            buttonText="Set Account Properties"
            :tooltipText="propertiesTooltipText"
            :theme="theme"
            :disabled="buttonLimits"
            :action="changeProperties"
          ></button-with-tooltip>
          <regular-button
            buttonText="Next"
            :theme="theme"
            :disabled="buttonLimits"
            :action="next"
          ></regular-button>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import amplitude from "amplitude-js";
import { mapActions, mapMutations, mapState } from "vuex";
import { getCurrentPeriod, getPreviousRunIntFromString } from "@/utils/period-util.js";
import { getMonthYearFromYearMonthPeriod } from "@/utils/format-util.js";

import AdvancedAccountTable from "@/components/common/AdvancedAccountTable.vue";
import ChangeProperties from "@/components/dialogs/ChangeProperties.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import InceptionFilter from "@/components/filters/InceptionFilter.vue";
import ColumnConfigurator from "@/components/dialogs/ColumnConfigurator.vue";

export default {
  emits: ["next"],
  components: {
    AdvancedAccountTable,
    ChangeProperties,
    ErrorDialog,
    RunningDialog,
    WarningDialog,
    SuccessDialog,
    RegularButton,
    ButtonWithTooltip,
    InceptionFilter,
    ColumnConfigurator,
  },
  data: () => ({
    numberOfLoadings: 0,
    activeEffect: false,
    warningMessage: "",
    showWarningDialog: false,
    updateSuccess: false,
    searchError: false,
    loading: false,
    showChangeInception: false,
    showConfiguratorDialog: false,
    savedHeaders: [],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "colorThemeAll"]),
    ...mapState("inceptions", ["pagination", "inceptionHeaders"]),
    ...mapState("inceptionAccounts", [
      "inceptionAccounts",
      "totalInceptionAccounts",
      "totalDistinctInceptionAccounts",
      "selectedInceptionAccounts",
      "totalSelectedInceptionAccounts",
      "inceptionAllSelected",
      "maxItems",
    ]),
    ...mapState("addUpdateAccounts", ["updatingProperties", "updateError"]),
    ...mapState("filters", ["inceptionFilters", "quickSearchInception"]),
    ...mapState("columnConfigurator", ["inceptionTableSettingName"]),
    propertiesTooltipText() {
      return `Account inception and termination properties. <br />
              (${this.formattedPeriod} billing data)`;
    },
    updatingError: {
      get() {
        return this.updateError;
      },
      set(newValue) {
        this.setUpdateError(newValue);
      },
    },
    allSelected: {
      get() {
        return this.inceptionAllSelected;
      },
      set(newValue) {
        this.setInceptionAllSelected(newValue);
      }
    },
    selected: {
      get() {
        return this.selectedInceptionAccounts;
      },
      set(newValue) {
        this.setSelectedInceptionAccounts(newValue);
      },
    },
    totalSelected: {
      get() {
        if (this.allSelected) {
          return this.totalInceptionAccounts;
        } else {
          return this.totalSelectedInceptionAccounts;
        }
      },
      set(newValue) {
        this.setTotalSelectedInceptionAccounts(newValue);
      },
    },
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    sortBy() {
      if (this.options.sortBy && this.options.sortBy.length === 1) {
        return this.options.sortBy[0];
      } else {
        return null;
      }
    },
    sortDirection() {
      if (this.options.sortDesc && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] === false ? "Ascending" : "Descending";
      } else {
        return "Ascending";
      }
    },
    buttonDisabled() {
      return this.totalSelected == 0;
    },
    buttonLimits() {
      return (
        this.buttonDisabled ||
        (!this.allSelected && this.totalSelected > this.maxItems)
      );
    },
    theme() {
      if (this.allSelected) {
        return this.colorThemeAll;
      } else {
        return this.colorTheme;
      }
    },
    quickSearchValue() {
      return this.$refs.filters.$refs.searchFilter.quickSearchValue;
    },
    billingPeriod() {
      return getCurrentPeriod();
    },
    formattedPeriod() {
      return getMonthYearFromYearMonthPeriod(this.billingPeriod);
    },
    isColumnConfiguratorUsed() {
      return this.inceptionHeaders.length !== this.headersSelected.length;
    },
    headersSelected() {
      let filteredHeaders = this.savedHeaders.filter(
        (header) => header.selected == true
      );
      return filteredHeaders;
    },
  },
  watch: {
    totalSelected() {
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    selected(val) {
      this.totalSelected = val.length;
    },
    allSelected(val) {
      if (val === true) {
        this.setQuickSearchFromTempParam();
      }
    },
  },
  methods: {
    ...mapMutations("inceptions", ["setPagination"]),
    ...mapMutations("inceptionAccounts", [
      "setInceptionAccounts",
      "setSelectedInceptionAccounts",
      "setTotalSelectedInceptionAccounts",
      "setInceptionAllSelected",
    ]),
    ...mapActions("inceptionAccounts", ["inceptionSearch"]),
    ...mapActions("addUpdateAccounts", ["updateAccountProperties"]),
    ...mapMutations("addUpdateAccounts", ["setUpdateError"]),
    ...mapMutations("filters", ["setQuickSearchInception"]),
    showConfigurator() {
      this.showConfiguratorDialog = true;
    },
    setQuickSearchFromTempParam() {
      if (
        this.quickSearchValue &&
        this.quickSearchValue !== this.quickSearchInception
      ) {
        this.setQuickSearchInception(this.quickSearchValue);
      }
    },
    async refreshOrCut(pageSize) {
      let array = this.inceptionAccounts;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setInceptionAccounts(this.inceptionAccounts.slice(0, pageSize));
      }
    },
    async refresh(params) {
      this.numberOfLoadings++;
      this.loading = true;

      if (params && params.page) {
        this.options.page = params.page;
      }

      let payload = {
        page: this.page,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        searchParam: this.quickSearchValue
      };
      let result = await this.inceptionSearch(payload);
      if (!result) {
        this.searchError = true;
      }

      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.loading = false;
      }
    },
    async confirmUpdate(payload) {
      if (payload.all) {
        payload = {
          ...payload,
          runType: "Inception",
          period: getPreviousRunIntFromString(this.billingPeriod)
        };
      }
      let result = await this.updateAccountProperties(payload);
      if (!result.error) {
        let notUpdatedCount = result.totalFailed;
        if (notUpdatedCount > 0) {
          if (result.all) {
            if (result.allFailed) {
              this.updatingError = true;
            } else {
              this.showWarningDialog = true;
              this.warningMessage = "Warning! Some attributes are not updated.";
            }
          } else {
            this.showWarningDialog = true;
            this.warningMessage =
              "Warning! " + notUpdatedCount + " attributes not updated.";
          }
        } else {
          this.updateSuccess = true;
        }
      }
      await this.refreshAndDeselect();
    },
    selectNone() {
      this.selected = [];
      this.allSelected = false;
    },
    changeProperties() {
      this.showChangeInception = !this.showChangeInception;
    },
    async next() {
      this.setQuickSearchFromTempParam();
      this.$emit("next");
      amplitude.getInstance().logEvent("Inception [Next]", {
        "Number of accounts selected": this.selected.length
      });
    },
    async refreshAndDeselect() {
      this.selectNone();
      await this.refresh();
    }
  },
  async mounted() {
    await this.refreshAndDeselect();
  },
};
</script>
<style scoped>
.bottom-align {
  display: flex;
  align-items: flex-end;
}
</style>
