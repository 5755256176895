<template>
  <v-dialog v-model="show" persistent max-width="750px" @keydown.esc="cancel">
    <running-dialog
      message="Linking accounts to group. Please wait..."
      :showDialog.sync="saving"
    ></running-dialog>
    <v-card>
      <v-card-title>
        <span class="headline"> Add Account to Account Group</span>
        <span class="headline">
          Please select entity with ACCOUNT_GROUP type</span
        >
        <span class="right-corner">
          <v-icon @click="cancel" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-card-text>
        <v-combobox
          :loading="isLoading"
          :search-input.sync="search"
          :filter="customFilter"
          hide-no-data
          single-line
          hide-details
          clearable
          item-value="id"
          label="Search for account group"
          append-icon="mdi-magnify"
          return-object
          :color="colorTheme.comboBox"
        >
        </v-combobox>
        <v-data-table
          fixed-header
          class="scrollable-table__medium"
          :headers="headers"
          :items="accountGroupsSelect"
          item-key="id"
          :loading="loading"
          :single-select="true"
          v-model="selected"
          @click:row="selectRow"
          @dblclick:row="openRow"
        >
          <template v-slot:[`item.id`]="{ item }">
            <cell-clipboard :text="item.id" />
          </template>
        </v-data-table>
        <v-btn
          class="mb-2 mr-2"
          :color="colorTheme.button"
          dark
          @click="cancel"
        >
          Close
        </v-btn>
        <v-btn
          class="mb-2 mr-2"
          :color="colorTheme.button"
          :dark="!returnButtonDisabled"
          @click="returnAction"
          :disabled="returnButtonDisabled"
        >
          Return
        </v-btn>
        <v-btn
          class="mb-2 mr-2"
          :color="colorTheme.button"
          :dark="!disableLinkButton"
          @click="confirm"
          :disabled="disableLinkButton"
        >
          Link
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CellClipboard from "@/components/common/CellClipboard.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";

export default {
  props: ["showDialog", "accounts"],
  emits: [
    "update:showDialog",
    "showLinkingSuccessDialog",
    "showLinkingWarningDialog",
    "showLinkingErrorDialog"
  ],
  components: {
    CellClipboard,
    RunningDialog,
  },
  data: () => ({
    isLoading: false,
    items: [],
    saving: false,
    selected: [],
    parent: null,
    loading: false,
    search: null,
    numberOfLoadings: 0,
    disableLinkButton: true,
    returnButtonDisabled: true,
    editedItem: {
      startDate: "",
      feeFrequency: "",
      paidBy: "",
    },
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: false,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Type",
        value: "type",
      },
    ],
  }),
  computed: {
    ...mapState("inceptionGroupPicker", ["accountGroupsSelect", "parents"]),
    ...mapState("userConfig", ["colorTheme"]),
    show() {
      if (this.showDialog) {
        this.resetSearch();
        this.getGroupsLevel(null);
      }
      return this.showDialog;
    },
    isModification() {
      return this.attributeSelected !== null;
    },
  },
  watch: {
    search(val) {
      if (this.search != null && this.search != "") {
        this.filterEntities(val);
      } else {
        this.isLoading = false;
        this.getGroupsLevel(null);
      }
    },
    items() {
      let array = [];
      this.items.forEach((element) => {
        let i = 0;
        array.forEach((a) => {
          if (element.id == a.id) {
            i++;
          }
        });
        if (i == 0) array.push(element);
      });
      this.setAccountGroupsSelect(array);
    },
  },
  methods: {
    ...mapMutations("inceptionGroupPicker", [
      "setParents",
      "setAccountGroupsSelect",
      "incrementGroupPickerComponentKey",
    ]),
    ...mapActions("inceptionGroupPicker", [
      "getAccountGroupsLevel",
      "linkAccountToGroup",
    ]),
    ...mapActions("searchEntities", ["searchEntitiesByListOfTypes"]),
    customFilter() {
      return true;
    },
    async filterEntities(filter) {
      this.numberOfLoadings++;
      this.isLoading = true;
      try {
        let result = await this.searchEntitiesByListOfTypes({
          filter: filter,
          types: ["ACCOUNT_GROUP"],
        });
        if (result) {
          this.items = result;
        }
      } catch (error) {
        console.log(error);
      }
      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.isLoading = false;
      }
    },
    async openRow(event, payload) {
      if (payload.item.type != "ACCOUNT_GROUP") {
        const openItem = payload.item;
        this.parent = openItem;
        this.getGroupsLevel(openItem);
        let newParents = this.parents;
        newParents.push(openItem);
        this.setParents(newParents);
        this.returnButtonDisabled = this.parents.length < 1;
      }
    },
    selectRow(event, payload) {
      const selectedItem = payload.item;
      this.disableLinkButton = selectedItem.type != "ACCOUNT_GROUP";
      this.selected = [];
      this.selected.push(selectedItem);
    },
    async getGroupsLevel(payload) {
      this.selected = [];
      this.getAccountGroupsLevel(payload);
    },
    async returnAction() {
      let parrentsFromState = this.parents;
      if (parrentsFromState.length < 2) {
        this.getGroupsLevel();
        this.setParents([]);
      } else {
        let trimmedParents = parrentsFromState.slice(
          0,
          parrentsFromState.length - 1
        );
        let lastParent = trimmedParents[trimmedParents.length - 1];
        this.getGroupsLevel(lastParent);
        let newParents = parrentsFromState.slice(
          0,
          parrentsFromState.length - 1
        );
        this.setParents(newParents);
      }
      this.disableLinkButton = true;
    },
    async cancel() {
      this.selected = [];
      this.setAccountGroupsSelect([]);
      this.setParents([]);
      this.$emit("update:showDialog", false);
      this.incrementGroupPickerComponentKey();
    },
    async confirm() {
      this.saving = true;
      let result = await this.linkAccountToGroup({
        groupId: this.selected[0].id,
        accountIds: this.accounts.map((a) => a.id)
      });
      this.saving = false;

      if (result === true) {
        this.$emit("showLinkingSuccessDialog", true);
      } else if (result === false) {
        this.$emit("showLinkingWarningDialog", true);
      } else {
        this.$emit("showLinkingErrorDialog", true);
      }
      this.cancel();
    },
    resetSearch() {
      this.search = "";
    },
  },
};
</script>

<style>
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #4ac5b9 !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #4ac5b9 !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: #4ac5b9 !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: r#4ac5b9 !important;
}
</style>
