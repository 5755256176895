<template>
  <div class="ml-4">
    <search-filter
      ref="searchFilter"
      screen="InceptionFilters"
      :headers="headersWithoutIgnoredValues"
      :multiSelectColumns="multiSelectColumns"
      :columnsWithPredicates="columnsWithPredicates"
      :billingFeeTypes="billingFeeTypes"
      :dropDownValues="dropDownValues"
      :isPredicates="isPredicates"
      :containsPredicates="containsPredicates"
      :inGroupPredicates="inGroupPredicates"
      :hasPredicates="hasPredicates"
      :doesNotHavePredicates="doesNotHavePredicates"
      :dateColumns="dateColumns"
      :filters="filters"
      :results="results"
      :totalResults="totalResults"
      :totalDistinctResults="totalDistinctResults"
      :theme="theme"
      :showCalendarChip="false"
      :showActiveAccountsChip="true"
      :quickSearchParam="quickSearchInception"
      :refresh="refresh"
      :setQuickSearchParam="setQuickSearchInception"
      :resetQuickSearchParam="resetQuickSearchInception"
      :loadPrepopulatedData="loadPrepopulatedData"
    ></search-filter>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SearchFilter from "./SearchFilter.vue";

export default {
  props: [
    "headers",
    "theme",
    "results",
    "totalResults",
    "totalDistinctResults",
    "refresh"
  ],
  components: {
    SearchFilter
  },
  data: () => ({
    multiSelectColumns: [
      "status",
      "billingType",
      "billableStatus",
      "custodian"
    ],
    ignoredHeaderValues: [
      "aum",
      "aumDate",
      "brokerageAccountNumber",
      "billingRunType",
      "accountStatus"
    ],
    dateColumns: ["billingStartDate", "billingEndDate"],
    columnsWithPredicates: [
      "id",
      "name",
      "repCode",
      "paidBy",
      "billingFeeType"
    ],
    isPredicates: ["id", "name", "repCode", "paidBy"],
    containsPredicates: ["id", "name", "repCode", "paidBy"],
    inGroupPredicates: ["id", "name", "paidBy"],
    hasPredicates: ["billingFeeType"],
    doesNotHavePredicates: ["billingFeeType"]
  }),
  computed: {
    ...mapState("inceptionAccounts", ["accountStatusChipValue"]),
    ...mapState("filters", ["inceptionFilters", "quickSearchInception"]),
    ...mapState("prepopulatedData", [
      "statuses",
      "feeFrequencies",
      "accountStatuses",
      "billingFeeTypes",
      "billables",
      "custodians"
    ]),
    headersWithoutIgnoredValues() {
      let filteredHeaders = this.headers.filter(
        x => !this.ignoredHeaderValues.includes(x.value)
      );
      filteredHeaders.unshift({
        text: "Quick search",
        value: "quickSearch"
      });
      filteredHeaders.push({
        text: "Fee Schedule Type",
        value: "billingFeeType"
      });
      return filteredHeaders;
    },
    filters: {
      get() {
        return this.inceptionFilters;
      },
      set(newValue) {
        this.setInceptionFilters(newValue);
      }
    }
  },
  watch: {
    accountStatusChipValue(val) {
      this.checkAccountStatus(val);
      this.refresh();
    },
  },
  methods: {
    ...mapMutations("filters", [
      "setInceptionFilters",
      "setQuickSearchInception",
      "resetQuickSearchInception"
    ]),
    ...mapActions("prepopulatedData", ["loadFeeReviewPrepopulatedData"]),
    dropDownValues(column) {
      switch (column) {
        case "status":
          return this.statuses;
        case "billingType":
          return this.feeFrequencies;
        case "custodian":
          return this.custodians;
        case "billingFeeType":
          return this.billingFeeTypes;
        case "billableStatus":
          return this.billables;
        default:
          return [];
      }
    },
    checkAccountStatus(chipValue) {
      let valueCheck = chipValue === "activeClosedAccounts";
      let accountStatusValue = valueCheck ? [] : chipValue;
      let statusFilters = this.filters.filter(
        e => e.column === "accountStatus"
      );
      if (statusFilters.length > 0) {
        let index = this.filters.findIndex(e => e.column === "accountStatus");
        this.filters[index].value = accountStatusValue;
      } else {
        let accountStatusFilter = {
          column: "accountStatus",
          predicate: "is",
          text: "Account Status",
          value: accountStatusValue
        };
        this.filters.push(accountStatusFilter);
      }
    },
    async loadPrepopulatedData() {
      await this.loadFeeReviewPrepopulatedData();
    }
  }
};
</script>
